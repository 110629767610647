
import { GlobalPlayer } from '../global-player'

const storageKey = 'GlobalPlayer'
const player = new GlobalPlayer(storageKey, {
    position: { right: 15, bottom: 140 },
    zIndex: 199,
    audio: null,
    play: false,
    currentTime: 0
}, localStorage)

console.log('global player init')

const lastRecordKey = storageKey + '/lastRecordTime'
let _lastRecordTime = parseInt(localStorage.getItem(lastRecordKey) || Date.now().toString())
const emitAction = (lectureId: number, currentTime: number) => {

    const now = Date.now()

    if (!localStorage.getItem(lastRecordKey)) {
        localStorage.setItem(lastRecordKey, _lastRecordTime.toString())
    }

    if (now - _lastRecordTime > 60e3) {
        const statsImgUrl = location.hostname === 'm.lizhiweike.com'
                ? 'https://stat.lizhiweike.com/__t.gif'
                : 'http://193.112.214.135/__t.gif'
        const src = `${statsImgUrl}?ts=${Date.now()}&lzwk_id=${localStorage.lzwk_id}&v=111&stats_account_id=${localStorage.lzwk_id}&props_account_id=${localStorage.lzwk_id}&action=longest_learn_time&account_id=${localStorage.lzwk_id}&lecture_id=${lectureId}&learn_time=${currentTime}`

        let statsImg = document.getElementById('stats-img') as HTMLImageElement
        if (!statsImg) {
            statsImg = document.createElement('img')
            statsImg.id = 'stats-img'
            statsImg.style.position = 'fixed'
            statsImg.style.left = '-1px'
            statsImg.style.top = '-1px'
            statsImg.style.width = '1px'
            statsImg.style.height = '1px'
            document.body.appendChild(statsImg)
        }
        statsImg.src = src

        // 第二个打点
        setTimeout(() => {
            const statsImgUrl = location.hostname === 'm.lizhiweike.com'
                ? 'https://stat.lizhiweike.com/__t.gif'
                : 'http://193.112.214.135/__t.gif'
            const nextSrc = `${statsImgUrl}?action=classroom_stat_online&ts=${Date.now()}&lecture_id=${lectureId}&account_id=${localStorage.lzwk_id}&props_account_id=${localStorage.lzwk_id}&lzwk_id=${localStorage.lzwk_id}&playback_rate=1&v=111`
            statsImg.src = nextSrc
        }, 300)

        if ((window as any).DataService) {
            (window as any).DataService.emitAction('longest_learn', { learn_time: currentTime })
        }

        _lastRecordTime = now
        localStorage.setItem(lastRecordKey, _lastRecordTime.toString())
    }
}

// 时间和暂停
const lastTimeKey = storageKey + '/LastTime'
const lastTime = parseInt(localStorage.getItem(lastTimeKey) || '0')
if (Date.now() - lastTime >= 10 * 60 * 1e3) {
    player.setPlay(false)
}

player.onTimeUpdate(status => {
    const { audio, currentTime } = status
    if (audio) {
        localStorage.setItem(lastTimeKey, Date.now().toString())
        localStorage.setItem(`Lecture/AutoSave/${audio.id}`, currentTime.toString())
        emitAction(audio.id, currentTime)
    }
})

player.player.playbackRate(parseFloat(localStorage.getItem('UserConfig/playbackRate') || '1'))

const timer = setInterval(() => {
    // 适配直播课详情页
    if (/^\/lecture2\//.test(location.pathname)) {
        return
    }

    const audios = document.getElementsByTagName('audio')
    for (let i = 0; i < audios.length; i++) {
        const audio = audios[i];
        if (audio.id !== 'wk-global-player-audio' 
        && audio.id !== 'player_audio' // 适配傻逼 weike-fe
        && !audio.paused 
        && !audio.muted) {
            player.hide()
            clearInterval(timer)
            return
        }
    }
}, 100)

// const whiteList = [
//     25710956, 62224840, 52756123, 59581182, 39385431,
//     59250940, 6583, 39284250, 53605227, 55796490,
//     44083007, 77236754, 37336825, 35867827, 60412797,
//     68336744, 50765, 74781067, 30335003, 13, 38786228,
//     33916017, 47360208, 28295145, 46955852, 88888
// ]
// const id = parseInt(localStorage.lzwk_id) || null

// const access = id && ((id % 10) < 5)
// if (!id || (whiteList.indexOf(id) < 0 && !access)) {
//     player.show = () => void (0)
// }

export default player