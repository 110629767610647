/*
// 从 https://gitlab.weike.fm/weike-html5-frontend/lecture/-/blob/master/src/services/access.model.ts 复制而来
大一统的权限设计

身份：
管理员:（所有者）能听课，能看数据，能管理
转播者: 权限继承自普通学员，能看数据
直播间vip: 能听非转播课
普通学员: 啥都不能干

对课程：
无权限（需要购买专栏，需要购买课程，输入密码)
能听课
能看数据
能管理

对专栏：
无权限 （需要购买专栏）
能听课
能看数据
能管理

单独授权：
普通学员密码课输入了密码
普通学员买了单课
成为讲师一节课的讲师

超级会员订阅

普通学员买了专栏

权限分为两种类型，听课权限 和 管理权限
*/

// Role 里面只关于权限的部分

export interface IARole {
	is_liveroom_vip: boolean
	is_manager: boolean
	is_relayer: boolean
}

type IAChannelType = 'open_channel' | 'pay_channel' | string // 用于判断 channel 的权限

// ChannelInfo 只关于权限的部分
export interface IAChannelInfo {
	role: IARole
	
	channel: {
		id: number
		channel_type: IAChannelType
		is_relay: boolean
	}
	
	channel_access: {
		granted: boolean
		subscribed: boolean
	}
}

type IALectureType = 'open_lecture' | 'pay_lecture' | 'pay_channel' | 'password_lecture' | string

// ChannelInfo 里面的 Lecture 权限
export interface IAChannelLecture {
	id: number
	lecture_type: IALectureType
	lecture_mode: 'default' | string
	is_relay: boolean
}

// UserChannelInfo 只关于权限的部分配合 Channel Info 使用
export interface IAChannelUserInfo {
	access_datas: {
		[lecture_id: number]: {
			granted: boolean
			subscribed: boolean
		}
	}
}

// Lecture 只关于权限的部分
export interface IALectureInfo {
	role: IARole
	
	lecture: {
		id: number
		lecture_type: IALectureType
		lecture_mode: 'default' | string
		is_relay: boolean
	}
	
	lecture_access: {
		granted: boolean
		subscribed: boolean
	}
	
	channel: {
		id: number
		channel_type: IAChannelType
		is_relay: boolean
	} | null
	
	channel_access: {
		granted: boolean
		subscribed: boolean
	} | null
	
}

// 管理权限权限
export interface IAManageAccess {
	canVisitData: boolean // 可以查看数据
	canModify: boolean // 可以操作
	canManage: boolean // 管理权限
}

export type IAChannelAccess = {
	canListen: boolean
	needBuyChannel: boolean
	needSubscribe: boolean
} & IAManageAccess

export type IALectureAccess = {
	canListen: boolean
	needBuyLecture: boolean
	needBuyChannel: boolean
	needPassword: boolean
	needSubscribe: boolean
	isAudition: boolean // 是否为试听课
} & IAManageAccess

// AccessModel 权限的模型层
export class AccessModel {
	
	private static getManageAccess({role}: { role: IARole }): IAManageAccess {
		const {is_manager, is_relayer} = role
		
		return {
			canVisitData: is_manager || is_relayer,
			canModify: is_manager,
			canManage: is_manager || is_relayer
		}
	}
	
	public static getChannelInfoAccess(channelInfo: IAChannelInfo): IAChannelAccess {
		
		const {
			role: {is_liveroom_vip, is_manager},
			channel: {channel_type, is_relay},
			channel_access: {granted, subscribed}
		} = channelInfo
		
		const manageAccess = AccessModel.getManageAccess(channelInfo)
		
		const canListen
			= is_manager // 管理员必须能听
			|| channel_type === 'open_channel' // 免费专栏
			|| granted // 已授权专栏
			|| (is_liveroom_vip && !is_relay) // liveroom_vip
		
		const needBuyChannel = !canListen && channel_type === 'pay_channel'
		
		const needSubscribe = channel_type === 'open_channel' && !subscribed && !is_manager
		
		return {
			canListen,
			needBuyChannel,
			needSubscribe,
			...manageAccess
		}
	}
	
	public static getLectureInfoAccess(lectureInfo: IALectureInfo): IALectureAccess {
		
		const {
			role: {is_manager, is_liveroom_vip},
			lecture: {lecture_type, is_relay, lecture_mode},
			lecture_access: {granted, subscribed}
		} = lectureInfo
		
		const manageAccess = AccessModel.getManageAccess(lectureInfo)
		
		let result
		if (lectureInfo.channel && lectureInfo.channel_access && lectureInfo.channel.channel_type === 'pay_channel') {
			// 收费专栏里面的权限
			const channelAccess = AccessModel.getChannelInfoAccess(lectureInfo as IAChannelInfo)
			
			const canListen
				= is_manager // 管理员能听
				|| lecture_type === 'open_lecture' // 免费课能听
				|| granted // 授权了能听
				|| (lecture_type !== 'password_lecture' && !is_relay && is_liveroom_vip) // 非密码非转播的 liveroom vip 能听
				|| (lecture_type !== 'password_lecture' && channelAccess.canListen) // 专栏授权了以后的非密码课能听
			
			const needBuyLecture =
				!canListen && (lecture_type === 'pay_lecture') // 不能听且付费专栏
			
			const needBuyChannel =
				channelAccess.needBuyChannel && ((!canListen) || (canListen && lecture_type === 'open_lecture'))
			
			const needPassword =
				!canListen && lecture_type === 'password_lecture' // 不能听且是密码课
			
			const isAudition =
				!channelAccess.canListen && lecture_type === 'open_lecture' // 专栏不能听的免费课
			
			result = {
				canListen,
				needBuyLecture,
				needBuyChannel,
				needPassword,
				isAudition,
				...manageAccess
			}
			
		} else {
			// 没有专栏，或者在免费专栏里面的单课
			const canListen
				= is_manager // 管理员能听
				|| lecture_type === 'open_lecture' // 免费课能听
				|| granted // 授权了能听
				|| (lecture_type !== 'password_lecture' && lectureInfo.channel_access && lectureInfo.channel_access.granted) // 为了兼容从付费专栏转成免费专栏的情况
				|| (lecture_type !== 'password_lecture' && !is_relay && is_liveroom_vip) // 非密码非转播的 liveroom vip 能听
			
			const needBuyChannel = false
			const needBuyLecture = !canListen && lecture_type === 'pay_lecture'
			const needPassword = !canListen && lecture_type === 'password_lecture'
			const isAudition = false
			
			result = {
				canListen,
				needBuyLecture,
				needBuyChannel,
				needPassword,
				isAudition,
				...manageAccess
			}
		}
		
		const needSubscribe: boolean
			= lecture_type === 'open_lecture'
			&& result.canListen
			&& !(subscribed || (lectureInfo.channel_access && lectureInfo.channel_access.subscribed || false))
		
		return {
			needSubscribe,
			...result
		}
	}
	
}

